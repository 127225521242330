var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block comparison-chart white-block" }, [
    _c(
      "div",
      { staticClass: "container px-5 mx-auto pt-8 lg:pt-12 pb-12 lg:pb-16" },
      [
        _vm.data.heading
          ? _c(
              "div",
              { staticClass: "pb-8" },
              [
                _vm.data.heading
                  ? _c(
                      "base-heading",
                      { attrs: { size: "h1", color: "brown" } },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.data.heading) + "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "chart mx-auto leading-normal max-w-2xl text-lg lg:text-xl xl:text-2xl num-columns-2"
          },
          [
            _c("div", { staticClass: "top-row flex flex-wrap" }, [
              _c("div", { staticClass: "c1 border-r border-brown" }, [
                _vm._v(" ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "c2 sm:border-t border-brown bg-brown-lighter tr-bg hover:bg-brown-light border-r"
                },
                [
                  _c(
                    "span",
                    { staticClass: "block p-4 bg-white px-4 lg:px-6" },
                    [
                      _c("base-image", {
                        attrs: {
                          src: _vm.data.columnHeaderOne.headerImageSrc,
                          retina_src:
                            _vm.data.columnHeaderOne.headerImageRetina,
                          srcset_webp:
                            _vm.data.columnHeaderOne.headerImageWebpSrcset,
                          alt: _vm.data.columnHeaderOne.headerImageAlt
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "block text-white w-full font-bold pad" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.data.columnHeaderOne.headerText) +
                          "\n          "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "c2 sm:border-t border-brown bg-brown-lighter tr-bg hover:bg-brown-light sm:border-r"
                },
                [
                  _c(
                    "span",
                    { staticClass: "block p-4 bg-white px-4 lg:px-6" },
                    [
                      _c("base-image", {
                        attrs: {
                          src: _vm.data.columnHeaderTwo.headerImageSrc,
                          retina_src:
                            _vm.data.columnHeaderTwo.headerImageRetina,
                          srcset_webp:
                            _vm.data.columnHeaderTwo.headerImageWebpSrcset,
                          alt: _vm.data.columnHeaderTwo.headerImageAlt
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "block text-white w-full font-bold pad" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.data.columnHeaderTwo.headerText) +
                          "\n          "
                      )
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm._l(_vm.data.rows, function(row, r) {
              return _c(
                "div",
                { key: "row-" + r, staticClass: "flex flex-wrap" },
                [
                  _c("div", {
                    staticClass:
                      "c1 text-center sm:text-left font-bold py-2 lg:py-3 px-5 sm:pl-0 sm:pr-5 sm:lg:pr-7 border-b sm:border-r border-brown",
                    domProps: { innerHTML: _vm._s(row.rowHeader) }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "c2 rich-text border-b border-r border-brown pad",
                    domProps: { innerHTML: _vm._s(row.columnContentOne) }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "c2 rich-text border-b border-brown pad sm:border-r",
                    domProps: { innerHTML: _vm._s(row.columnContentTwo) }
                  })
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "buttons-row flex flex-wrap mt-10" }, [
              _c("div", { staticClass: "c1" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c2 px-5 lg:px-7 text-center" },
                [
                  _vm.data.actionButtons.columnOne.url
                    ? _c("base-button", {
                        attrs: {
                          url: _vm.data.actionButtons.columnOne.url,
                          label: _vm.data.actionButtons.columnOne.text,
                          target: _vm.data.actionButtons.columnOne.target,
                          color: "red"
                        }
                      })
                    : _c("span", [_vm._v(" ")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c2 px-5 lg:px-7 text-center" },
                [
                  _vm.data.actionButtons.columnTwo.url
                    ? _c("base-button", {
                        attrs: {
                          url: _vm.data.actionButtons.columnTwo.url,
                          label: _vm.data.actionButtons.columnTwo.text,
                          target: _vm.data.actionButtons.columnTwo.target,
                          color: "red"
                        }
                      })
                    : _c("span", [_vm._v(" ")])
                ],
                1
              )
            ])
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }