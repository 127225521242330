<template>
  <div v-if="success" class="rich-text">
    <p>
      Thank you for contacting Wildlife Acoustics Support! A support case number has been created and a confirmation email has been sent to <span v-text="email"></span>.
    </p>

    <p>
      A support ticket has been created. For most support requests, we respond within 1-2 business days.
    </p>

    <p>
      Want to find your answer faster? Check out the following links:
      <ul>
        <li>For answers to a wide variety of common questions about all of our products, see our <a href='/resources/faqs'>FAQ page</a>.</li>
        <li>For guided demonstrations on using our recorders and software, see our <a href='/resources/video-tutorials/kaleidoscope-pro-software/en/kaleidoscope-pro-software-viewer-for-bat-analysis-english'>Video Tutorials</a>.</li>
        <li>For questions about phone and tablet compatibility with the Echo Meter Touch, please see our <a href='/products/ios-device-compatibility'>iOS Compatibility Page</a> and <a href='/products/android-device-compatibility'>Android Compatibility Page</a>.</li>
        <li>For more specific questions about any of our products, please see our <a href='/resources/user-guides'>User Guides</a>.</li>
      </ul>
    </p>

    <p>
      If these resources helped you find your answer or if you think our resources could be improved, please let us know in our support survey.
    </p>

    <p>
      If your support request is urgent, please call us at 1 (978) 369-5225 and choose option 2 for Support. Our standard Support hours are 9am - 5pm Monday - Friday Eastern (Boston) time excluding U.S. holidays. When calling outside of business hours, please leave a message and we will get back to you as quickly as we can.
    </p>

    <p>
      If you are not already a member, we also invite you to join our mailing list.  You’ll be the first to know about technical service bulletins, new products, training sessions, webinars, news, and more. We are careful about the emails we send and strive to include valuable information in all of our communications. You can customize your email settings in the <a href='/account'>My Account</a> section of the website to only get notified of the things that are important to you. You can subscribe to our mailing list <a href='/products#mailinglist'>here</a>.
    </p>

    <p>
      Thank you again and we will respond shortly.
    </p>

    <p>
      -The Wildlife Acoustics Support Team
    </p>
  </div>

  <div v-else>
    <div class="text-left text-lg">
      <div class="lg:flex">
        <div class="lg:w-1/2 lg:pr-10">
          <h1 class="mb-8 font-bold text-2hxl xl:text-3xl">Contact Information</h1>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="first_name">Given Name or Nickname *</label>
            <form-input
              input_type="text"
              name="first_name"
              v-model="first_name"
              :class="{ 'border-red': errors.includes('first_name') }"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="last_name">Surname *</label>
            <form-input
              type="text"
              name="last_name"
              :class="{ 'border-red': errors.includes('last_name') }"
              v-model="last_name"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="email">Email Address *</label>
            <form-input
              type="text"
              name="email"
              :class="{ 'border-red': errors.includes('email') }"
              v-model="email"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="job_title">Job Title</label>
            <form-input
              type="text"
              name="job_title"
              v-model="job_title"
              :class="{ 'border-red': errors.includes('job_title') }"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="country">Country *</label>
            <form-select
              name="country"
              v-model="country"
              :class_names="[errors.includes('country') ? 'border-red' : '']"
              :options="country_options"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="organization_name">Organization Name</label>
            <form-input
              type="text"
              name="organization_name"
              v-model="organization_name"
              :class="{ 'border-red': errors.includes('organization_name') }"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="organization_type">Organization Type</label>
            <form-select
              name="organization_type"
              v-model="organization_type"
              :options="organization_types"
              :class_names="[errors.includes('organization_type') ? 'border-red' : '']"
              :disabled="is_loading"
            >
            </form-select>
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="phone_number">Phone Number</label>
            <form-input
              type="text"
              name="phone"
              :class="{ 'border-red': errors.includes('phone') }"
              v-model="phone"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="address_1">Address 1</label>
            <form-input
              type="text"
              name="address_1"
              :class="{ 'border-red': errors.includes('address_1') }"
              v-model="address_1"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="address_2">Address 2</label>
            <form-input
              type="text"
              name="address_2"
              :class="{ 'border-red': errors.includes('address_2') }"
              v-model="address_2"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="city">City</label>
            <form-input
              type="text"
              name="city"
              :class="{ 'border-red': errors.includes('city') }"
              v-model="city"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="state">{{ state_label }}</label>

            <div v-if="has_states" :class="['input-select-wrapper relative', is_loading ? 'disabled' : '']">
              <template v-if="country === 'UNITED KINGDOM'">
                <select
                    v-model="state"
                    name="state"
                    :disabled="is_loading"
                    :class="['input-select', errors.includes('state') ? 'border-red' : '']"
                >
                  <optgroup v-for="(states, group) in states_for_country" :label="group" :key="'state-group-' + group">
                    <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                  </optgroup>
                </select>
              </template>

              <template v-else>
                <select
                  v-model="state"
                  name="state"
                  :disabled="is_loading"
                  :class="['input-select', errors.includes('state') ? 'border-red' : '']"
                >
                  <option v-for="state in states_for_country" :key="state" :value="state">{{ state }}</option>
                </select>
              </template>
            </div>

            <form-input
              v-else
              type="text"
              name="state"
              v-model="state"
              :class="{ 'border-red': errors.includes('state') }"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="postal_code">Postal Code</label>
            <form-input
              type="text"
              name="postal_code"
              v-model="postal_code"
              :class="{ 'border-red': errors.includes('postal_code') }"
              :disabled="is_loading"
            />
          </div>
        </div>

        <div class="lg:w-1/2 lg:pl-10">
          <h1 class="my-8 lg:mt-0 font-bold text-2hxl xl:text-3xl">Product and Support Request Information</h1>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="product">Product *</label>
            <form-select
              name="product"
              v-model="product"
              :options="products"
              :class_names="[errors.includes('product') ? 'border-red' : '']"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="request_type">Type of Support Request *</label>
            <form-select
              name="request_type"
              v-model="request_type"
              :options="request_type_options"
              :class_names="[errors.includes('request_type') ? 'border-red' : '']"
              :disabled="is_loading && !product"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5" v-show="ask_serial_number">
            <label for="serial_number">Product Serial Number</label>
            <form-input
              type="text"
              name="serial_number"
              v-model="serial_number"
              :class="{ 'border-red': errors.includes('serial_number') }"
              :disabled="is_loading"
            />
            <p class="mt-2 italic" v-show="serial_number_instructions">{{ serial_number_instructions }}</p>
          </div>

          <div v-show="show_software_fields">
            <div class="sm:w-p60 lg:w-full mb-5" v-show="Object.keys(oses).length > 0">
              <label for="os">Operating System</label>
              <form-select
                name="os"
                v-model="os"
                :value="Object.keys(oses).length === 1 ? Object.keys(oses)[0] : null"
                :options="oses"
                :class_names="[errors.includes('os') ? 'border-red' : '']"
                :disabled="is_loading"
              />
            </div>

            <div class="sm:w-p60 lg:w-full mb-5" v-show="os">
              <label for="os_version">Operating System Version</label>
              <form-input
                type="text"
                name="os_version"
                v-model="os_version"
                :class="{ 'border-red': errors.includes('os_version') }"
                :disabled="is_loading"
              />
            </div>

            <div class="sm:w-p60 lg:w-full mb-5" v-show="ask_software_version">
              <label for="software_version">{{ software_version_label }}</label>
              <form-input
                type="text"
                name="software_version"
                v-model="software_version"
                :class="{ 'border-red': errors.includes('software_version') }"
                :disabled="is_loading"
              />
              <p class="mt-2 italic" v-show="software_version_instructions">{{ software_version_instructions }}</p>
            </div>
          </div>

          <div class="sm:w-p60 lg:w-full mb-5" v-show="ask_firmware_version">
            <label for="firmware_version">Firmware Version</label>
            <form-input
              type="text"
              name="firmware_version"
              v-model="firmware_version"
              :class="{ 'border-red': errors.includes('firmware_version') }"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5" v-if="getSiteName() !== 'faunatech'">
            <label for="point_of_sale">Company Where Purchased</label>
            <form-select
              name="point_of_sale"
              v-model="point_of_sale"
              :class_names="[errors.includes('point_of_sale') ? 'border-red' : '']"
              :options="point_of_sales"
              :disabled="is_loading"
            />
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="subject">Subject of Support Request</label>
            <form-input
              type="text"
              name="subject"
              v-model="subject"
              :class="{ 'border-red': errors.includes('subject') }"
              :disabled="is_loading"
            />
          </div>

          <div class="w-full mb-5">
            <label for="message">Description of Support Request *</label>
            <textarea
              type="text"
              name="message"
              rows="4"
              class="input-text"
              style="font-family: inherit;"
              v-model="message"
              :class="{ 'border-red': errors.includes('message') }"
              :disabled="is_loading"
            />
          </div>

          <h1 class="my-8 font-bold text-2hxl xl:text-3xl">Project Information</h1>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="project_type">Project Type</label>
            <form-select
              name="project_type"
              v-model="project_type"
              multiple="true"
              :rows="Object.keys(project_types).length"
              :options="project_types"
              :class_names="[errors.includes('project_type') ? 'border-red' : '']"
              :disabled="is_loading"
            >
            </form-select>
          </div>

          <div class="sm:w-p60 lg:w-full mb-5">
            <label for="study_subject">Study Subject</label>
            <form-select
              name="study_subject"
              v-model="study_subject"
              multiple="true"
              :rows="Object.keys(study_subjects).length"
              :options="study_subjects"
              :class_names="[errors.includes('study_subject') ? 'border-red' : '']"
              :disabled="is_loading"
            >
            </form-select>
          </div>

          <div class="sm:w-p60 lg:w-full mb-5" v-show="study_subject.includes('Other')">
            <label for="study_subject_other">Study Subject (Other)</label>
            <form-input
              type="text"
              name="study_subject_other"
              v-model="study_subject_other"
              :class="{ 'border-red': errors.includes('study_subject_other') }"
              :disabled="is_loading"
            />
          </div>
        </div>
      </div>

      <div class="mb-5 lg:mt-5">
        <form-checkbox
          label="Subscribe to our mailing list and stay up-to-date on Technical Support Bulletins, Quarterly Newsletters and General Announcements."
          name="subscribe"
          v-model="subscribe"
          :disabled="is_loading"
        >
        </form-checkbox>
      </div>

      <div class="mt-4 text-lg leading-normal" v-if="error_message">
        <span class="text-red" v-html="error_message"></span>
      </div>

      <div class="mt-4">
        <button
          type="submit"
          @click.prevent="submit()"
          :class="[
            'relative bg-white font-bold uppercase rounded border-4 text-xl py-3 tr-color tr-bg hover:bg-grey-lighter',
            'px-10 text-brown border-brown hover:text-black hover:bg-grey-light'
          ]"
        >
          <base-icon
            icon-name="loading"
            width="28"
            height="28"
            viewbox="0 0 32 32"
            class="loading absolute ml-3 left-0 centered-y"
            v-show="isLoading"
          >
            <icon-loading></icon-loading>
          </base-icon>
          <span class="inline">Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";
import VueAxios from "../../js/modules/vue-axios";

export default {
  mixins: [HelpersMixin],
  props: {
    countries: [],
    states: [],
    state_labels: {},
    products_config: {},
    point_of_sales: [],
    site_name: String
  },

  data: function() {
    return {
      // lookups
      organization_types: {
        Government: "Government",
        "For-profit": "For-profit",
        University: "University",
        "Non-profit": "Non-profit",
        Individual: "Individual",
        "K-12": "K-12"
      },
      project_types: {
        Biodiversity: "Biodiversity",
        "Ecoacoustics/Soundscapes": "Ecoacoustics/Soundscapes",
        "Monitoring to Inform Species Conservation": "Monitoring to Inform Species Conservation",
        "Environmental Impact Assessments": "Environmental Impact Assessments",
        "Animal Behavior Studies": "Animal Behavior Studies",
        "Education and Community Outreach": "Education and Community Outreach"
      },
      study_subjects: {
        Bird: "Bird",
        Bat: "Bat",
        Frog: "Frog",
        Primate: "Primate",
        Insect: "Insect",
        Rodent: "Rodent",
        Noise: "Noise",
        Other: "Other"
      },

      // form fields
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      city: "",
      address_1: "",
      address_2: "",
      state: "",
      postal_code: "",
      country: "",
      organization_name: "",
      organization_type: "",
      job_title: "",
      project_type: "",
      study_subject: "",
      study_subject_other: "",
      product: "",
      request_type: "",
      serial_number: "",
      os: "",
      os_version: "",
      software_version: "",
      firmware_version: "",
      point_of_sale: "",
      subject: "",
      message: "",
      subscribe: "",

      // state
      success: false,
      is_loading: false,
      errors: [],
      error_message: null
    };
  },

  computed: {
    country_options: function() {
      if (!this.countries) {
        return {};
      }

      const dict = {};

      for (const country of this.countries) {
        dict[country] = country;
      }

      return dict;
    },

    has_states: function() {
      const states_for_country = this.states_for_country;

      return !!states_for_country;
    },

    states_are_grouped: function() {
      const states_for_country = this.states_for_country;

      if (Array.isArray(states_for_country)) {
        return false;
      } // if the states are a simple list
      if (states_for_country instanceof Object) {
        return true;
      } // if the states are grouped

      return false;
    },

    states_for_country: function() {
      if (!this.country || !this.states) {
        return false;
      }

      const states_for_country = this.states[this.country];

      return states_for_country;
    },

    state_label: function() {
      const label = this.state_labels[this.country];

      if (!label) {
        return "State/Province";
      }

      return label;
    },

    products: function() {
      const dict = {};

      for (const key in this.products_config) {
        dict[key] = key;
      }

      return dict;
    },

    request_type_options: function() {
      const dict = {};

      const config = this.products_config[this.product];

      if (!config) {
        return {};
      }

      const types_for_product = config["request_types"];

      if (!types_for_product) {
        return {};
      }

      for (const request_type of types_for_product) {
        const name = request_type.name;

        dict[name] = name;
      }

      return dict;
    },

    oses: function() {
      const dict = {};

      const config = this.products_config[this.product];

      if (!config) {
        return {};
      }

      const oses = config["oses"];

      if (!oses) {
        return {};
      }

      for (const key of oses) {
        dict[key] = key;
      }

      return dict;
    },

    ask_software_version: function() {
      const config = this.products_config[this.product];

      if (!config) {
        return false;
      }

      return config["ask_software_version"];
    },

    software_version_label: function() {
      const config = this.products_config[this.product];

      if (!config) {
        return "Software Version";
      }

      return config["software_version_label"];
    },

    software_version_instructions: function() {
      const config = this.products_config[this.product];

      if (!config) {
        return false;
      }

      return config["software_version_instructions"];
    },

    ask_firmware_version: function() {
      const config = this.products_config[this.product];

      if (!config) {
        return false;
      }

      return config["ask_firmware_version"];
    },

    ask_serial_number: function() {
      const config = this.products_config[this.product];

      if (!config) {
        return false;
      }

      return config["ask_serial_number"];
    },

    serial_number_instructions: function() {
      const config = this.products_config[this.product];

      if (!config) {
        return false;
      }

      return config["serial_number_instructions"];
    },

    show_software_fields: function() {
      const config = this.products_config[this.product];

      if (!config) {
        return false;
      }

      if (!this.request_type) {
        return false;
      }

      const request_type_config = config.request_types.find(e => e.name === this.request_type);

      if (!request_type_config) {
        return false;
      }

      return request_type_config.show_software_fields;
    }
  },

  methods: {
    submit() {
      let self = this;

      // check for errors

      self.errors = [];

      if (!self.first_name) {
        self.errors.push("first_name");
      }
      if (!self.last_name) {
        self.errors.push("last_name");
      }
      if (!self.email) {
        self.errors.push("email");
      }
      if (!self.country) {
        self.errors.push("country");
      }
      if (!self.product) {
        self.errors.push("product");
      }
      if (!self.request_type) {
        self.errors.push("request_type");
      }
      if (!self.message) {
        self.errors.push("message");
      }

      if (self.errors.length > 0) {
        self.error_message = "Some fields are missing data. Please review the errors above.";
        return;
      }

      // upload the data

      self.is_loading = true;

      let formData = new FormData();
      formData.append("action", "wildlife/netsuite/support-form");
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("fname", self.first_name);
      formData.append("lname", self.last_name);
      formData.append("email", self.email);
      formData.append("phone", self.phone);
      formData.append("country", self.country);
      formData.append("addr1", self.address_1);
      formData.append("addr2", self.address_2);
      formData.append("city", self.city);
      formData.append("state", self.state);
      formData.append("zip", self.postal_code);
      formData.append("orgname", self.organization_name);
      formData.append("orgtype", self.organization_type);
      formData.append("jobtitle", self.job_title);
      formData.append("projectType", JSON.stringify(self.project_type));
      formData.append("studySubject", JSON.stringify(self.study_subject));
      formData.append("studySubjectDescription", self.study_subject_other);
      formData.append("productName", self.product);
      formData.append("issueType", self.request_type);
      formData.append("serialNumbers", self.serial_number);
      formData.append("operatingSystem", self.os);
      formData.append("operatingSystemVersion", self.os_version);
      formData.append("softwareVersion", self.software_version);
      formData.append("firmwareVersion", self.firmware_version);
      formData.append("pointOfSale", self.point_of_sale);
      formData.append("subject", self.subject);
      formData.append("details", self.message);
      formData.append("subscribe", self.subscribe);

      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };

      VueAxios(options)
        .then(response => {
          let data = response.data;

          if (data.success) {
            self.success = true;
          } else {
            self.error_message = data.message;
          }

          self.is_loading = false;
        })
        .catch(error => {
          self.error_message = "An error occured. Please try again.";
          self.is_loading = false;
        });
    }
  },

  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    FormCheckbox: () => import(/* webpackChunkName: "form-checkbox" */ "../components/form/FormCheckbox.vue"),
    FormInput: () => import(/* webpackChunkName: "form-input" */ "../components/form/FormInput.vue"),
    FormSelect: () => import(/* webpackChunkName: "form-select" */ "../components/form/FormSelect.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../icons/IconLoading.vue")
  }
};
</script>
